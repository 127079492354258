/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Thumbor from '@common_image';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import cx from 'classnames';
import Link from 'next/link';
import React from 'react';
import { COLORS } from '@theme_vars';

const MenuChildren = ({ data, handleClick, generateLink, mainData }) =>
// eslint-disable-next-line no-unused-vars
// const [active, setActive] = React.useState(0);
// const child = data[active];

// const chevronRight = `
//     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 inline-block relative right-0">
//         <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
//     </svg>
// `;

    (
        <div className="main-content px-[23px] pt-[25px] pb-[30px]">
            {mainData && mainData.show_left_sidebar && (
                <div className="left-sidebar" style={{ width: mainData.left_sidebar_width }}>
                    <CmsRenderer content={mainData.left_sidebar_html} />
                </div>
            )}
            <div className="w-full desktop:grid desktop:gap-6">
                <div className="swift-menu-group max-h-[45vh] overflow-y-auto">
                    <ul
                        className={cx('nav-column swift-nav-column-left', 'min-w-[150px] desktop:grid desktop:grid-cols-4', {
                            '!bg-neutral-white': mainData && mainData.dropdown_bgcolor === '',
                        })}
                    >
                        {data.map((val, idx) => {
                            let label;
                            if (val.include_in_menu) {
                                // Manage position in menu LV2
                                const itemStyleLv2 = {
                                    ...val?.position_in_menu && { order: val?.position_in_menu },
                                };

                                if (val.children.length > 0) {
                                    label = `<div class="flex flex-1 justify-between"><span>${val.name}</span></div>`;
                                } else {
                                    label = val.name;
                                }
                                return (
                                    <li key={idx} className="order-last" style={itemStyleLv2}>
                                        <Link key={idx} href={generateLink(val)[0]} as={generateLink(val)[1]} prefetch={false} legacyBehavior>
                                            <a
                                                onClick={() => handleClick(val)}
                                                className={cx(
                                                    // active === idx ? 'active' : '',
                                                    '!text-neutral-black',
                                                    '!text-base',
                                                    '!font-bold',
                                                    '!leading-[20px]',
                                                    '!px-0',
                                                    '!pt-0',
                                                    '!pb-[15px]',
                                                    'swift-nav-menu-lv2',
                                                    'mobile:!text-[17px]',
                                                    'capitalize',
                                                    'leading-[25px]',
                                                    'hover:underline',
                                                )}
                                                // onMouseEnter={() => setActive(idx)}
                                                // eslint-disable-next-line react/no-danger
                                                dangerouslySetInnerHTML={{
                                                    __html: label,
                                                }}
                                            />
                                        </Link>
                                        <div className={cx('nav-column', { 'swift-nav-column-right': val.children.length > 0 })}>
                                            <div className={`${val.image_path ? 'lg:basis-9/12' : 'lg:basis-full'} row grid`}>
                                                {val.children.map((lvl3, id3) => {
                                                    if (lvl3.include_in_menu) {
                                                        // Manage position in menu LV3
                                                        const itemStyleLv3 = {
                                                            ...lvl3?.position_in_menu && { order: lvl3?.position_in_menu },
                                                        };

                                                        return (
                                                            <div className="lg:basis-3/12 order-last" key={id3} style={itemStyleLv3}>
                                                                <Link href={generateLink(lvl3)[0]} as={generateLink(lvl3)[1]} prefetch={false} legacyBehavior>
                                                                    <a
                                                                        onClick={() => handleClick(lvl3)}
                                                                        className={cx(
                                                                            '!text-neutral-black',
                                                                            'text-base',
                                                                            '!font-normal',
                                                                            '!leading-[20px]',
                                                                            '!px-0',
                                                                            '!pt-0',
                                                                            '!pb-[10px]',
                                                                            'swift-nav-menu-lv3',
                                                                            '!text-[14px]',
                                                                            'capitalize',
                                                                            'leading-[20px]',
                                                                            'hover:underline',
                                                                        )}
                                                                    >
                                                                        {lvl3.name}
                                                                    </a>
                                                                </Link>
                                                            </div>
                                                        );
                                                    }

                                                    return false;
                                                })}
                                            </div>
                                            {val.image_path ? (
                                                <div className="lg:basis-3/12">
                                                    <Thumbor src={val.image_path} className="img_cat" width={960} height={577} quality={80} alt={val.name} />
                                                </div>
                                            ) : null}
                                        </div>
                                    </li>
                                );
                            }

                            return false;
                        })}
                    </ul>
                </div>
            </div>
            {mainData && mainData.show_right_sidebar && (
                <div className="right-sidebar" style={{ width: mainData.right_sidebar_width }}>
                    <CmsRenderer content={mainData.right_sidebar_html} />
                </div>
            )}
            <style jsx>
                {`
                    .row {
                        margin: 0;
                    }
                    .swift-nav-column-left {
                        background: ${mainData && mainData.dropdown_bgcolor ? mainData.dropdown_bgcolor : '#FFFFFF'};
                        // padding: 16px;
                    }
                    .swift-nav-column-right {
                        // padding: 16px 16px 16px 0;
                    }
                    .list-item__menu a {
                        font-weight: normal;
                        padding-bottom: 5px;
                        padding-top: 0px;
                    }
                    .swift-menu-group::-webkit-scrollbar-track {
                        background: 0 0;
                    }
                    .swift-menu-group::-webkit-scrollbar-thumb {
                        background-color: ${COLORS.secondary[600]};
                        border-radius: 5px;
                        border: 1px solid transparent;
                    }
                    .swift-menu-group::-webkit-scrollbar {
                        width: 5px;
                    }
                    .swift-menu-group {
                        overflow-x: hidden;
                        padding: 0;
                        scrollbar-width: thin;
                        scrollbar-color: ${COLORS.secondary[600]} transparent;
                    }
                `}
            </style>
        </div>
    );
export default MenuChildren;
